const link1 = document.getElementById("link1");
const link2 = document.getElementById("link2");
const link3 = document.getElementById("link3");
const link4 = document.getElementById("link4");

const isAuthorized = JSON.parse(localStorage.getItem("isAuthorized"));

if (isAuthorized) {
  [link1, link2, link3, link4].map(function (link) {
    link.href = "/cabinet";
    link.textContent = "Dashboard";
  });
}
